.container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  margin: 0;
}

.half {
  width: 50%;
  padding: 4rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.leftHalf {
  background-color: #157347;
  color: white;
}

.rightHalf {
  position: relative;
}

.logo {
  height: 60px;
  position: absolute;
  top: 8rem;
  left: 4rem;
}

.form {
  /* width: 280px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 1rem;
}
